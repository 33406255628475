import React from "react";
 
const VideoPage = ({ video }) => {
  return (
    <section className="videoPage snapScrolling">
      <h1>Videos</h1>
      <div className='videoDiv'>
        <iframe
          src="https://www.youtube.com/embed/videoseries?list=PLOvEvVI9MgB7PrpYrwCAbW3g7CaEq9e6G"
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
          title="YouTube Playlist"
          style={{
            width: '100%',
            height: '100%',
          }}
        ></iframe>
      </div>
    </section>
  );
};

export default VideoPage;