import React from 'react';
import 'animate.css';

export const CampsiteStatus = ({ statusItems }) => {
  return (
    <div className="campsiteStatus">
      <h2>Site Status</h2>
      <ul>
        {statusItems.map((item, index) => (
          <li key={index}>
            {index === 0 ? (
              <a href="/map">{item}</a>
            ) : (
              item
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};