import { useState, useRef } from "react";
import { Container } from "react-bootstrap";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import mapImage from "../assets/img/map.jpg";

export const Map = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const transformRef = useRef(null);

  const handleDoubleClick = (event) => {
    event.stopPropagation(); // Prevents TransformWrapper from handling the event
    setIsFullscreen((prev) => !prev);
  };

  return (
    <section className={`map snapScrolling`} id="map">
      <div className="mapTitle">
        <h1>Map</h1>
      </div>
      <Container className="mapContainer">
        <div
          className={`mapDiv ${isFullscreen ? "fullscreen" : ""}`}
          onDoubleClick={handleDoubleClick} // Handles double-click manually
        >
          <TransformWrapper ref={transformRef}>
            <TransformComponent>
              <img src={mapImage} alt="map" />
            </TransformComponent>
          </TransformWrapper>
        </div>
        {!isFullscreen && (
          <>
            <div className="mapDownload">
              <a href={mapImage} download="map.jpg" style={{ textDecorationColor: "white" }}>
                <h2>Download Map</h2>
              </a>
            </div>
            <div className="mapInstructions">
              <h2>Instructions</h2>
              <ul>
                <li>Scroll Down for Legend</li>
                <li>Double Click to FullScreen</li>
                <li>Scroll / Pinch to Zoom</li>
                <li>Click and Drag to Pan</li>
              </ul>
            </div>
          </>
        )}
      </Container>
    </section>
  );
};