import { Map } from "./Map.js";
import { MapBack } from "./MapBack.js";

export const MapPage = () => {
    return (
      <div className="MapPage snapScrolling">
        < Map />
        < MapBack />
      </div>
    );
}