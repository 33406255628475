import { useState, useRef } from "react";
import mapImage from "../assets/img/map-back.jpg";
import brochure from "../assets/img/brochure.pdf";
import { Container } from "react-bootstrap";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"

export const MapBack = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const transformRef = useRef(null);

  const handleDoubleClick = (event) => {
    event.stopPropagation(); // Prevents TransformWrapper from handling the event
    setIsFullscreen((prev) => !prev);
  };

  return (
    <section
      className={`map mapBack snapScrolling `}
      id="map"
    >
      <div class="mapTitle">
        <h1>Legend</h1>
      </div>
      <Container className="mapContainer">
        <div 
          className={`mapDiv ${isFullscreen ? "fullscreen" : ""}`}
          onDoubleClick={handleDoubleClick} // Handles double-click manually
        >
        <TransformWrapper ref={transformRef}>
          <TransformComponent>
          <img src={mapImage} alt="map"/>
          </TransformComponent>
        </TransformWrapper>
        </div>
          <>
            <div className="mapDownload">
              <a href={brochure} download="brochure.pdf" style={{ textDecorationColor: 'white' }}>
                <h2>Download Brochure</h2>
              </a>
            </div>
            <div className="mapInstructions">
              <h2>Map Details</h2>
              <ul>
                <li>Find Site Info</li>
                <li>Trail Descriptions</li>
                <li>Rules & Safety Precautions</li>
              </ul>
            </div>
          </>
      </Container>
    </section>
  );
};