import { Banner } from "./Banner.js";
import { Camping } from "./Camping.js";
import { Welcome } from "./Welcome.js";
import VideoPage  from "./VideoPage.js";

export const Home = () => {
    return (
      <div className="Home snapScrolling">
        < Banner />
        < Welcome />
        < Camping />
        < VideoPage />
      </div>
    );
}