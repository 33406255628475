import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from './components/NavBar';
import { Footer } from './components/Footer';
import { Home } from "./components/Home.js";
import { Camping } from "./components/Camping.js";
import VideoPage  from "./components/VideoPage.js";
import { MapPage } from "./components/MapPage.js";
//import { Donations } from "./components/Donations.js";
import { About } from "./components/About.js";
import { Sponsors } from "./components/Sponsors.js";
import { Routes, Route} from 'react-router-dom';
import ImagePage from "./components/ImagePage";

function App() {
  
  // Define routes here
  const routes = [
    "north",
    "north/kager",
    "north/division",
    "north/pinkut",
    "north/ethel-f-wilson",
    "north/augier",
    "north/pendleton-bay",
    "north/taltapin",
    "north/coop",
    "north/richmond",
    "north/maxan",
    "north/agate-point",
    "north/mclure-pit",
    "north/govt-point",
    "north/colleymount",
    "north/noralee",
    "north/noralee/noralee-east",
    "north/noralee/noralee-west",

    "south",
    "south/west-francois",
    "south/lund",
    "south/wistaria",
    "south/ootsa",
    "south/chinook",  
    "south/takysie",
    "south/takysie/takysie-site-a",
    "south/takysie/takysie-site-b",
    "south/indian-bay",
    "south/uncha",
    "south/binta",
    "south/binta/binta-north",
    "south/binta/binta-south",
  ];

  return (
    <div className="App" id="scrollContainer" style={{backgroundColor: '#658147',}}>
      <NavBar/>
      <Routes>
        {/* Top-level routes */}
        <Route path='/' element={<Home />} />
        <Route path='/Camping' element={<Camping />} />
        {/* <Route path='/donations' element={<Donations />} /> */}
        <Route path='/videos' element={<VideoPage />} />
        <Route path='/map' element={<MapPage />} />
        <Route path='/sponsors' element={<Sponsors />} />
        <Route path='/about' element={<About />} />
        {/* Use the new CampingRoutes component as the element */}
        {routes.map((folder) => (
          <Route key={folder} path={`/Camping/${folder}`} element={<ImagePage item={folder} />} />
        ))}
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
